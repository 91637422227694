

const Header = ({name}) => {
  return (
    <header>
      <h1>{name}</h1>
    </header>
  )
}

// Header.defaultProps = {
//   title:"Default Title"
// }

export default Header